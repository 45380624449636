import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconProviderElaGames = ({ className }) => (
  <svg
    className={classNames('producers-svg-icon', className)}
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M36 10H26L27.7413 30H30.989H34.2365L36 10Z" fill="white" />
    <path
      d="M47.6299 18C41.0177 18 36.783 19.7189 36.0772 24.5764H44.2497C44.5097 23.3434 45.6239 22.9323 47.6299 22.9323C50.2303 22.9323 50.8618 23.8665 50.8618 25.6975V26.1833C37.786 25.5481 35 28.3131 35 32.9465C35 37.0569 38.009 39 43.6182 39C47.1841 39 49.5244 37.8042 50.8618 36.4591V38.4395H60V25.6975C60 19.121 54.1678 18 47.6299 18ZM50.8618 32.984C49.8588 33.9182 48.2986 34.5907 46.3299 34.5907C45.1411 34.5907 43.7667 34.3291 43.7667 32.7971C43.7667 30.7421 46.2926 30.5925 50.8618 30.8168V32.984Z"
      fill="white"
    />
    <path
      d="M24.9744 30.3309C25.4774 21.0641 18.4702 18 12.6848 18C6.46819 18 0 20.8025 0 28.4999C0 36.1976 6.54004 39 12.8645 39C18.2187 39 23.4651 37.169 24.6869 31.7135H16.6016C15.883 32.7971 14.6971 33.5071 12.8645 33.5071C10.2413 33.5071 8.87585 32.1994 8.40864 30.3309H24.9744ZM12.6848 23.4928C14.9486 23.4928 16.1346 24.6139 16.6376 26.4449H8.44465C8.94768 24.726 10.1694 23.4928 12.6848 23.4928Z"
      fill="white"
    />
    <path
      d="M10.1353 45.8614H5.98974V47.5035H7.9158C7.73237 48.0028 7.26461 48.4637 6.26488 48.4637C5.01752 48.4637 4.20123 47.7819 4.20123 46.4951C4.20123 45.2181 4.83408 44.5363 6.08145 44.5363C7.0078 44.5363 7.47557 44.8435 7.7599 45.3812H9.99786C9.58517 43.6912 7.9525 43 6.09061 43C4.02698 43 2 43.9314 2 46.4951C2 49.0686 3.99945 50 6.25571 50C8.74137 50 10.383 48.8093 10.1353 45.8614ZM20.2121 49.856H22.4775L19.5059 43.1344H16.7051L13.7426 49.856H16.0081L16.4117 48.8188H19.7994L20.2121 49.856ZM17.0168 47.2633L17.9388 44.834H18.2998L19.2031 47.2633H17.0168ZM47.5883 48.31H43.3418V47.1865H47.2398V45.6309H43.3418V44.69H47.4966V43.1344H41.168V49.856H47.5883V48.31ZM56.5882 45.6598C55.4875 45.5444 54.6897 45.5061 54.6897 45.0451C54.6897 44.6612 55.1481 44.5267 56.0287 44.5267C56.8632 44.5267 57.3769 44.7284 57.3769 45.1893H59.5873C59.5873 44.0274 58.7801 43 56.0563 43C53.5615 43 52.525 43.7872 52.525 45.1509C52.525 46.4951 53.2221 46.9272 55.194 47.2442C57.0375 47.5419 57.6613 47.513 57.6613 47.9644C57.6613 48.3388 57.1476 48.4733 56.2856 48.4733C55.3867 48.4733 54.6528 48.3005 54.6528 47.6763H52.3783C52.3783 49.0877 53.4514 50 56.1662 50C58.6885 50 60 49.3374 60 47.7242C60 46.0247 58.5968 45.8807 56.5882 45.6598Z"
      fill="white"
    />
    <path
      d="M30.5001 47.5999L31.947 43H35V50H32.9089L32.9052 45.45L31.4229 50H29.5734L28.0911 45.45V50H26V43H29.053L30.5001 47.5999Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.5444 36.1718C32.932 35.8562 32.2775 35.63 31.6008 35.5002C32.2775 35.3706 32.932 35.1445 33.5444 34.8288C33.8347 34.6813 33.999 34.4325 33.999 34.1176C33.999 33.7217 33.7189 33.4054 33.3241 33.3415C33.1562 33.3162 33.0005 33.2386 32.8795 33.1195C32.7608 32.9985 32.683 32.843 32.6577 32.6751C32.5936 32.2803 32.2765 32.0002 31.8817 32.0002C31.5665 32.0002 31.3182 32.1645 31.1706 32.4546C30.8548 33.0675 30.6286 33.7223 30.4988 34.3993C30.3695 33.7223 30.1437 33.0672 29.8283 32.4544C29.6808 32.1643 29.4325 32 29.1175 32C28.7216 32 28.4054 32.2801 28.3412 32.6749C28.3161 32.8426 28.2384 32.9983 28.1195 33.1193C27.9984 33.2382 27.8429 33.316 27.6749 33.3411C27.2801 33.4052 27 33.7223 27 34.1171C27 34.4323 27.1645 34.6809 27.4546 34.8284C28.067 35.144 28.7214 35.3702 29.3982 35.5C28.7214 35.6296 28.067 35.8557 27.4546 36.1714C27.1645 36.3191 27 36.5672 27 36.8824C27 37.2781 27.2801 37.5944 27.6749 37.6587C27.8429 37.684 27.9984 37.7616 28.1195 37.8807C28.2382 38.0017 28.3159 38.1572 28.3412 38.3251C28.4054 38.7199 28.7227 39 29.1175 39C29.4325 39 29.6808 38.8357 29.8283 38.5456C30.1442 37.9332 30.3703 37.2787 30.5001 36.6024C30.6299 37.2787 30.856 37.9332 31.1717 38.5456C31.3192 38.8357 31.5675 39 31.8827 39C32.2786 39 32.5946 38.7199 32.6588 38.3251C32.6843 38.1572 32.7618 38.0019 32.8805 37.8807C33.0016 37.7618 33.1573 37.684 33.3251 37.6587C33.7199 37.5944 34 37.2775 34 36.8824C33.999 36.5677 33.8347 36.3193 33.5444 36.1718Z"
      fill="#FF1351"
    />
  </svg>
);

IconProviderElaGames.propTypes = {
  className: PropTypes.string
};

IconProviderElaGames.defaultProps = {
  className: ''
};
